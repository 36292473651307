import React, { Component } from "react";
import './App.css';
import {BrowserRouter as Router,Route,Routes } from 'react-router-dom';
import Shapp_p1 from './shapp_p1';
import Shapp_p2 from './shapp_p2';
import Shapp_p3 from './shapp_p3';
import Login  from './Login';
import LostPassword from './Lost_pw';
import Newuser from './newuser';
import Ticket  from './Ticket';

import Home  from './Home';
import Geoutilities  from './Geoutilities';
import Ves from './ves';
import Formazione from './formazione';
import Snapro from './snapro';
import Sartoria from './sartoriadigitale';
import Blog from './Blog';
import Garage from './garage';
import Wms from './WMS';
import Download from './download';
import Geodatabase from './art_geodatabase';
import Cpt from './art_cpt';
import Lique from './art_lique';
import Shp from './art_shapp';
import Shnp from './art_shnapp';
import ContactForm from './contact';
import ContactForm_free from './contact_free_version';
import Whoim from './whoim';
import Video from './video';

import Pricing from './pricing';
import Pay from './payment';

import jwt_decode from "jwt-decode";

    class App extends Component {
      
      state = {
        isAuthenticated: true,
      };


      componentDidMount() {
        if (localStorage.getItem('token') !== null) {

          console.log(localStorage.getItem('token'))

          const token = localStorage.getItem('token');
          const refreshToken = localStorage.getItem('refresh_token')

          var decodedToken=jwt_decode(token);
          var decodedToken2=jwt_decode(refreshToken);

          var dateNow = new Date();
          if(decodedToken.exp < dateNow.getTime()/1000){

          this.setState({ isAuthenticated: false });
          console.log('non autenticato')}

        } else {
          this.setState({ isAuthenticated: false });
          console.log('non autenticato')
        }
      }

      render() {
        console.log('deve deviare su page1')
        //se sono autenticato
        let routes = (

        <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/datageo" element={<Geodatabase />} />
                <Route path="/elab_cpt" element={<Cpt />} />
                <Route path="/elab_liq" element={<Lique />} />
                <Route path="/shapp_edu" element={<Shp />} />
                <Route path="/field_data" element={<Shnp />} />
                <Route path="/ves" element={<Ves />} />
                <Route path="/Geoutilities" element={<Geoutilities />} />
                <Route path="/Formazione" element={<Formazione />} />
                <Route path="/video" element={<Video />} />
                <Route path="/Snapro" element={<Snapro />} />
                <Route path="/SartoriaDigitale" element={<Sartoria />} />
                <Route path="/Login" element={<Login />} />
                <Route path="/Lost_pw" element={<LostPassword />} />
                <Route path="/newuser" element={<Newuser />} />
                <Route path="/contatti" element={<ContactForm />} />
                <Route path="/request_free_version" element={<ContactForm_free />} />
                <Route path="/chisono" element={<Whoim />} />
                <Route path="/garage_digitale" element={<Garage />} />
                <Route path="/area_download" element={<Download />} />
                <Route path="/wms" element={<Wms />} />
                <Route path="/sHapp" element={<Shapp_p1 />} />
                <Route path="/sHapp_lstdata" element={<Shapp_p2 />} />
                <Route path="/sHapp_insdata" element={<Shapp_p3 />} />
                <Route path="/Ticket" element={<Ticket />} />
                <Route path="/plan" element={<Pricing />} />
                <Route path="/payment" element={<Pay />} />
            </Routes>
            )

       //se non sono autenticato
        if (!this.state.isAuthenticated) {
        console.log('solo login')
           routes = (
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/datageo" element={<Geodatabase />} />
                <Route path="/elab_cpt" element={<Cpt />} />
                <Route path="/elab_liq" element={<Lique />} />
                <Route path="/shapp_edu" element={<Shp />} />
                <Route path="/field_data" element={<Shnp />} />
                <Route path="/ves" element={<Ves />} />
                <Route path="/Geoutilities" element={<Geoutilities />} />
                <Route path="/Formazione" element={<Formazione />} />
                <Route path="/video" element={<Login />} />
                <Route path="/Snapro" element={<Snapro />} />
                <Route path="/SartoriaDigitale" element={<Sartoria />} />
                <Route path="/Login" element={<Login />} />
                <Route path="/Lost_pw" element={<LostPassword />} />
                <Route path="/newuser" element={<Newuser />} />
                <Route path="/contatti" element={<ContactForm />} />
                <Route path="/request_free_version" element={<ContactForm_free />} />
                <Route path="/chisono" element={<Whoim />} />
                <Route path="/garage_digitale" element={<Login />} />
                <Route path="/area_download" element={<Login />} />
                <Route path="/wms" element={<Login />} />
                <Route path="/sHapp" element={<Login />} />
                <Route path="/sHapp_lstdata" element={<Login />} />
                <Route path="/sHapp_insdata" element={<Login />} />
                <Route path="/plan" element={<Pricing />} />
                <Route path="/payment" element={<Login />} />
            </Routes>

          );
        }

        return <Router>{routes}</Router>;
      }}


export default App
