import './expo_page.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Im30 from './V30.png';
import Liq from './liq_1.jpg';

function Wms() {
    const navigate = useNavigate();

    const imagesApp = [
        Im30, 
        Liq
    ];

    const linksA = [
        'https://repository.sofhare.com/mappe/vseq/{z}/{x}/{y}.png',
        'https://repository.sofhare.com/mappe/lique/{z}/{x}/{y}.png'// Replace with the actual link for Dimage
    ];

    const handleButtonClickApp = () => {
        const link = linksA[currentIndexApp]
        // Create a temporary input element to hold the link
        const tempInput = document.createElement('input');
        tempInput.value = link;
        document.body.appendChild(tempInput);

        // Select and copy the link to the clipboard
        tempInput.select();
        document.execCommand('copy');

        // Clean up the temporary input element
        document.body.removeChild(tempInput);

        // Optionally, you can alert the user that the link has been copied
        toast.success('Download link copied to clipboard!',{autoClose: 1000});
    };


    const descriptionsApp = [
        "Carta Vs, eq",
        "Carta IPL"
    ];


    const [currentIndexApp, setCurrentIndexApp] = useState(0); // State to track the current image index
     const prevImageApp = () => {
        setCurrentIndexApp((prevIndexApp) =>
            prevIndexApp === 0 ? imagesApp.length - 1 : prevIndexApp - 1
        );
    };

    // Function to go to the next image
    const nextImageApp = () => {
        setCurrentIndexApp((prevIndexApp) =>
            prevIndexApp === imagesApp.length - 1 ? 0 : prevIndexApp + 1
        );
    };
    
    
    
    return(
        <div>
            <div className="home-title">
                    <h1> Lista XYZ Tiles </h1>
            </div>
            <br />
            <div className="wms-list">
                <div className="geo-container">

                    <div className="image-carousel">

                        <div className="carousel-content">
                            <div className="image-and-description">

                            </div>
                        </div>

                    </div>

                    <div className="image-carousel">
                        <button className="arrow left" onClick={prevImageApp}>
                            &#10094; {/* Left Arrow */}
                        </button>
                        <div className="carousel-content">
                            <div className="image-and-description">
                                <h3 className="preview-title">Categorie</h3>
                                    <img
                                        src={imagesApp[currentIndexApp]} // Current image based on index
                                        alt={`Image ${currentIndexApp + 1}`}
                                        className="carousel-image"
                                    />
                                    <p
                                        className="image-blog-description clickable"
                                        onClick={handleButtonClickApp} // Make description clickable
                                    >
                                        {descriptionsApp[currentIndexApp]}
                                    </p>
                            </div>
                        </div>
                            <button className="arrow right" onClick={nextImageApp}>
                                &#10095; {/* Right Arrow */}
                            </button>
                            {/* Navigation Button */}
                    </div>

                    <div className="image-carousel">

                        <div className="carousel-content">
                            <div className="image-and-description">

                            </div>
                        </div>

                    </div>


                </div>
            </div>        

            <div className="descriptions">
                <br/>
            </div>
        </div>

    );
}


export default Wms;

