import './expo_page.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Manual from './manuali.png';
import Anapp from './mobile_app.png';
import Tool from './tool.png';
import Wms from './wms.png';

function Download() {

    const navigate = useNavigate(); // Create a navigate function

    const handleDownloadAPK = () => {
        window.open('https://www.repository.sofhare.com/android_app/shnapp.apk', '_blank');
    };

    const handleDownloadManual = () => {
        window.open('https://www.repository.sofhare.com/manuali/Manuale_Geo_Utilities_V2.0.pdf', '_blank');
    };

    const handleViewWMS = () => {
       navigate('/wms');
    };

    const handleCopyLink = () => {
        const link = 'http://repository.sofhare.com/repository_sH_geoTool_xa5ggy';

        // Create a temporary input element to hold the link
        const tempInput = document.createElement('input');
        tempInput.value = link;
        document.body.appendChild(tempInput);

        // Select and copy the link to the clipboard
        tempInput.select();
        document.execCommand('copy');

        // Clean up the temporary input element
        document.body.removeChild(tempInput);

        // Optionally, you can alert the user that the link has been copied
        toast.success('Download link copied to clipboard!',{autoClose: 1000});
    };

    return(

        <div>
            <div className="home-title">
                    <h1> Area Download</h1>
            </div>
            <br/>
            <div className="geo-container">

                 <div className="tools">
                    <div className="blog-preview">
                        <h3 className="preview-title">sHnapp</h3>
                        <img src={Anapp} alt="Blog Preview" className="preview-image" />
                        <p onClick={handleDownloadAPK} className="preview-text">
                            Scarica APK
                        </p>
                    </div>
                 </div>

                 <div className="tools">
                    <div className="blog-preview">
                        <h3 className="preview-title">Geo Utilities</h3>
                        <img src={Tool} alt="Blog Preview" className="preview-image" />
                        <p onClick={handleCopyLink} className="preview-text">
                            Copia repository
                        </p>
                    </div>
                 </div>

                 <div className="tools">
                    <div className="blog-preview">
                        <h3 className="preview-title">Manuali</h3>
                        <img src={Manual} alt="Blog Preview" className="preview-image" />
                        <p onClick={handleDownloadManual} className="preview-text">
                            Vai!
                        </p>
                    </div>
                 </div>

                <div className="tools">
                    <div className="blog-preview">
                        <h3 className="preview-title">XYZ Tiles</h3>
                        <img src={Wms} alt="Blog Preview" className="preview-image" />
                        <p onClick={handleViewWMS} className="preview-text">
                            Vai!
                        </p>
                    </div>
                 </div>


            </div>
            <div className="descriptions">

            </div>
        </div>

    );
}


export default Download;
